$load_background: null;
@if $brand == 'mybookie' {
  $load_background: #CFCDCD;
}
@if $brand == 'xbet' {
  $load_background: #CFCDCD;
}
@if $brand == 'betusracing' {
  $load_background: #CFCDCD;
}

.load {
  background: $load_background;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeLoadOpacity;
  //animation-timing-function: linear;
  background-size: 1200px 104px;
  color: transparent !important;
  pointer-events: none;
  * {
    color:transparent;
  }

  &:hover {
    cursor: default !important;
  }
}
@keyframes placeload {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeLoadOpacity {
  0% {
    opacity: 1;
  }
  40%{
    opacity: 0.5;
  }
  66%{
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
}
