@mixin regular-button{
  button{
    position: relative;
    background: $line-button-bg;
    color: $line-button-color;
    border-radius: 4px;
    width: 100%;
    min-height: 32px;
    margin-right: 4px;
    font: normal 500 normal 10px/13px $main_font;
    border: 2px solid transparent;

    @include mq-min(768px) {
      font: normal 500 normal 12px/15px $main_font;
      min-height: 40px;
      margin-right: 8px;
    }
    @include mq-min(1280px) {
      font: normal 500 normal 13px/18px $main_font;
      height: 48px;
    }

    @include mq-min(1600px) {
      font: normal 500 normal 14px/18px $main_font;
    }

    &:last-of-type{
      margin-right: 0;
    }

    span{
      color: $hyperlink-accent;
      font-weight: 600;
    }

    &.active{
      background: $lines_button_active_background;
      color: #fff;
      @if $brand == 'xbet' {
        color: #fff !important;
        span {
          color: #242526;
        }
      }
    }

    &:focus, &:active{
      outline: none;
      box-shadow: none;
    }

    &:before, &:after{
      content: "\62";
      text-transform: none !important;
      font-family: "mybv4" !important;
      opacity: 0;
      position: absolute;
      right: 3px;
      font-size: 7px;
      top: 30%;
      @include mq-min(768px) {
        right: 14px;
        font-size: 14px;
      }
      @include mq-min(1280px) {
        right: 16px;
        font-size: 16px;
      }
    }

    &:before{
      color: $odds_up_color;
    }

    &:after{
      color: $odds_down_color;
      transform: rotate(180deg);
    }

    &.button_line_change_color.button_line_change_up {
      animation: lineChangeUp 5s linear;
      &:before{
        animation: lineChangeDownOpacity 5s;
      }
    }
    &.button_line_change_color.button_line_change_down {
      animation: lineChangeDown 5s linear;
      &:after{
        animation: lineChangeDownOpacity 5s;
      }
    }
  }
}

@mixin large-button{
  button{
    &:not(.top-bet-btn) {
      max-width: 49%;
    }
    min-height: 32px;
    width: 100%;
    @include mq-min(768px) {
      max-width: 249px;
      min-height: 40px;
    }
    @include mq-min(992px) {
      max-width: 600px;
      min-height: 48px;
      flex: 0 0 49.6%;
    }
    &.button-top-bet{
      border-color: $lines-top-bet;
    }
  }
}

.top-bet-box{
  flex: 0 0 49.5%;
}
