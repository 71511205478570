.carousel-container {
  padding: 15px 8px;
  //background: var(--background-primary);

  @include mq-min(768px) {
    @if $brand == 'xbet' {
      padding: 0 0 0.8rem 0;
    }
    &.launch-game {
      padding: 10px 8px !important;
      border-radius: 8px;
      height: auto;
      width: 100%;

      @media (min-width: 780px) {
        width: 98%;
      }
    }

  }

  @media (max-width: 760px) {
    &.launch-game {
      padding: 0 0 !important;
    }
  }

  .carousel-item div {
    padding: 0 8px;
  }

  .carousel-item div img {
    border-radius: 8px;
  }

  .carousel-inner .carousel-item.active,
  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .arrow {
    display: block;
  }

  .button_game {
    img {
      min-height: 72px !important;
      max-height: 72px !important;

      &.launch-game {
        border-radius: 8px;
      }
    }
  }

  &:hover {
    .arrow {
      display: flex;
    }
  }


  .carousel-container-launch {
    width: 96%;
    left: 3%;
    @media (min-width: 780px) {
      width: 98%;
      left: 1%;
    }
  }

  .swiper-pagination {
    @if $brand == 'mybookie' {
      @media (min-width: 768px) {
        left: -17% !important;
      }
    }
    bottom: 0 !important;
  }

    .swiper-button-next {
      font-family: initial !important;
      background-image: url("../../../resources/images/default/carousel/Banner-Right-Arrow.svg");

      @if $brand == 'xbet' {
        background-image: url("../../../resources/images/default/carousel/Banner-Right-Arrow-xb.svg");
      } @else {
        background-image: url("../../../resources/images/default/carousel/Banner-Right-Arrow.svg");
      }
      @if ($brand == 'bigspin') {
        background-image: url("../../../resources/images/default/carousel/Banner-Right-Arrow-love2play.svg");
      }
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      background-position: center;
      background-size: 150%;

      @media (min-width: 1600px) {
        width: 48px;
        height: 48px;
      }

      &:after {
        content: '';
      }
    }


    .swiper-button-prev {
      font-family: initial !important;
      background-image: url("../../../resources/images/default/carousel/Banner-Left-Arrow.svg");
      @if $brand == 'xbet' {
        background-image: url("../../../resources/images/default/carousel/Banner-Left-Arrow-xb.svg");
      } @else {
        background-image: url("../../../resources/images/default/carousel/Banner-Left-Arrow.svg");
      }
      @if ($brand == 'bigspin') {
        background-image: url("../../../resources/images/default/carousel/Banner-Left-Arrow-love2play.svg");
      }
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      background-position: center;
      background-size: 150%;

      @media (min-width: 1600px) {
        width: 48px;
        height: 48px;
      }

      &:after {
        content: '';
      }
    }

  .odds-row-prev {
    background-image: url("../../../resources/images/default/carousel/Left-Arrow.svg") !important;
    background-size: 30px;
  }

  .odds-row-next {
    background-image: url("../../../resources/images/default/carousel/Right-Arrow.svg") !important;
    background-size: 30px;
  }

  .swiper-pagination-bullet-active {
    background: var(--accent-color) !important;
  }

  .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.5);
  }

  &.odds-banner {
    background: transparent;
    @if $brand == 'xbet' {
      padding: 15px 0 !important;
      @media (min-width: 1280px) {
        padding: 8px 0 !important;
      }
    }

    @if $brand == 'xbet' {
      padding: 0 8px !important;
      @media (max-width: 768px) {
        margin-top: 1rem;
      }
    }

    .swiper-button-next, .swiper-button-prev {
      width: 30px;
      height: 30px;
      top: 55%;
      @media (min-width: 1440px) {
        width: 40px;
        height: 40px;
      }
    }

    .swiper-button-next {
      right: -5px;
    }

    .swiper-button-prev {
      left: -5px;
    }
  }
}

#sportsCarousel {
  .swiper-wrapper {
    padding-bottom: 22px;
  }
}

.img-banner {
  //height: 230px;
  //width: 450px;
  border-radius: 8px;
}


