.bet-boost {
  color: #fff;

  .bb {

    &-header {
      padding: 0 10px;
      background-color: $bg_title_lines;
      border-radius: 8px 8px 0 0;
    }

    &-body {
      background-color: $bg_body_line;

      .sub-title {
        font: normal 500 normal 14px/16px $main_font;
      }

    }

    &-button {
      padding: 0;
      background-color: $bg_button_line;
      border-radius: 0 0 8px 8px;

      button {
        color: #fff;
      }

      .odds {
        font: normal 600 normal 22px/26px $main_font;

        &-invalid {
          text-decoration: line-through;
          text-decoration-color: red;
          text-decoration-thickness: 2px;
        }

      }
    }

    &-card {
      border: none;
      background-color: transparent;
    }

    &-accent {
      color: $accent;
    }

    &-svg {
      fill: $accent;
    }
  }

}