@if $brand == 'xbet' {
  .game-banners {
    background: #283C39;
    color: #FFFFFF;
  }
}

.bi-lock-fill {
  fill: $accent;
}

.game-line {
  background: $bg_body_line;
  @if $brand == 'xbet' {
    border-top: 0.5px solid gray;
    margin-bottom: 0;
  }

  @if $brand == 'mybookie' {

    &:last-of-type{
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding-bottom: .5rem;
    }
  }

  &__only-time {
    margin-right: 10px;
    letter-spacing: 0;
    color: #FFFFFF !important;
    opacity: 0.75;
    font: normal 100 normal 10px/12px $main_font;
    @include mq-min(768px) {
      font: normal 500 normal 12px/15px $main_font;
    }
    @include mq-min(1280px) {
      font: normal 500 normal 14px/21px $main_font;
    }

    &__ob {
      font: normal 100 normal 10px/15px $main_font;
    }
  }

  &__header {
    background-color: $bg_main;
    @if $brand == 'mybookie' {
      //border-top-left-radius: 8px;
      //border-top-right-radius: 8px;
      padding-bottom: 0.2rem;
    }

    &.header-top-bets {
      background-color: $bgColor;
      padding-top: 10px;
    }

    p {
      color: $fontColor;
      @if $brand == 'xbet' {
        font: normal 100 normal 14px/16px $main_font;
      }@else{
        font: normal 100 normal 12px/14px $main_font;
      }
    }

    &_date {
      padding: 10px 0 3px 5px;
    }
  }

  //&:first-of-type {
  //  margin-top: 15px;
  //}

  &__time {

    &__date {
      font: normal 500 normal 10px/13px $main_font;
      color: $line-black-color;
      margin: 0;
      @include mq-min(768px) {
        font: normal 500 normal 12px/15px $main_font;
      }
      @include mq-min(1280px) {
        font: normal 500 normal 14px/18px $main_font;
      }

      .live-current-period {
        color: $lines-live;

        i {
          font-size: 0.5rem;
        }
      }
    }
  }

  &__type {
    &__name {
      max-width: 208px;
      width: 100%;
      font: normal 500 normal 10px/13px $main_font;
      margin: 0;
      color: $line-type-color;
      text-transform: uppercase;
      @include mq-min(768px) {
        font: normal 500 normal 12px/15px $main_font;
      }
      @include mq-min(1280px) {
        font: normal 500 normal 14px/18px $main_font;
      }

      &.tnt-name {
        max-width: 100%;
      }
    }
  }

  &__description, &__banner, &__description a, &__banner a {
    color: $line-black-color;
    font: normal 500 normal 10px/13px $main_font;
    @if $brand == 'xbet' {
      font: normal 500 normal 10px/13px $main_font;
      letter-spacing: 0;
    }
    @include mq-min(768px) {
      font: normal 500 normal 12px/15px $main_font;
      @if $brand == 'xbet' {
        font: normal 500 normal 14px/20px $main_font;
      }
    }
    @include mq-min(1280px) {
      font: normal 500 normal 12px/16px $main_font;
      @if $brand == 'xbet' {
        font: normal 500 normal 16px/24px $main_font;
      }
    }

    @include mq-min(1600px) {
      font: normal 500 normal 14px/16px $main_font;
      @if $brand == 'xbet' {
        font: normal 500 normal 16px/24px $main_font;
      }
    }

    &.league-title {
      background: $bg_main;
      padding: 10px 0 10px 15px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-bottom: 0;
      margin-top: 10px;
    }

  }

  //@if $brand == 'mybookie' {
  //  &__banner {
  //    border-bottom: 1px solid $nav_border;
  //  }
  //}

  &__visitor-team, &__home-team, &__top-bet-teams, &__tnt-team {
    &__name {
      max-width: 100%;
      word-break: break-word;
      color: $line-black-color;
      font: normal 700 normal 10px/12px $main_font;
      text-transform: capitalize;
      @include mq-min(768px) {
        font: normal 700 normal 12px/16px $main_font;
      }
      @include mq-min(1280px) {
        font: normal 700 normal 14px/16px $main_font;
      }

      a {
        color: $line-black-color;

        &:hover {
          color: $line-black-color;
        }
      }

      .score_live {
        color: $lines-live;
        position: absolute;
        right: 0;
      }
    }
  }

  &__top-bet-teams, &__tnt-team {
    &__name {
      max-width: 100%;
    }
  }

  &__visitor-line, &__home-line, &__top-bet-line, &__tnt-line {
    margin-bottom: 8px;
    @include regular-button;
  }

  &__top-bet-line {
    @include large-button;

    .top-bet-line-icon {
      position: absolute;
      color: $lines-top-bet;
      left: 8px;
      top: 38%;
      font-size: 10px;
      @include mq-min(768px) {
        top: 30%;
        left: 20px;
        font-size: 1rem;
      }
    }
  }

  &__tnt-line {
    flex-direction: row;
    flex-wrap: wrap;
    @include large-button;

    button {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  &__stats {
    margin: 0;

    button {
      color: $accent;
      font: normal 500 normal 10px/12px $main_font;
      border: none;
      background: none;
      text-decoration: underline;
      @include mq-min(768px) {
        font: normal 500 normal 12px/16px $main_font;
      }
      @include mq-min(1280px) {
        font: normal 500 normal 14px/16px $main_font;
      }

      &.inactive-stats {
        color: $lines-inactive-stats;
      }

      &:focus, &:active {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &__props {
    margin: 0;

    a {
      color: $hyperlink-accent;
      font: normal 500 normal 10px/12px $main_font;
      text-decoration: underline;
      @include mq-min(768px) {
        font: normal 500 normal 13px/21px $main_font;
      }
      @include mq-min(1280px) {
        font: normal 500 normal 13px/21px $main_font;
      }

      &.inactive-prop {
        color: $lines-inactive-prop;
      }
    }
  }

  .draw {
    &__info {
      padding: 10px 10px 0 0;
      margin-top: 5px;
      border-left: 15px solid $bg_body_line;
    }

    &__buttons {
      border-top: 0 solid #333;
      padding: 0 0 0 10px;
      margin-top: 0;
      border-right: 15px solid $bg_body_line;
    }
  }

  &__options {
    background-color: $bg_body_line;
    padding: 15px 0 15px 15px;
    color: $fontColor;
    font: normal 400 normal 14px/27px $main_font;
    margin-bottom: 0;
  }

  &__result-row{
    position: relative;

    &:after{
      content: '';
      width: 35%;
      background: #58595A;
      height: 1px;
      top: 15px;
      position: absolute;
      left: 3px;
    }
    &:before{
      content: '';
      width: 35%;
      background: #58595A;
      height: 1px;
      top: 15px;
      position: absolute;
      right: 3px;
    }
  }

}

.odds-banner{
  .game-line__visitor-team__name,.game-line__type__name, .game-line__time__date,.game-line__home-team__name  {
    font: normal 500 normal 11px/15px $main_font !important;
    @include mq-min(1440px) {
      font: normal 500 normal 12px/18px $main_font !important;
    }
    @include mq-min(1600px) {
      font: normal 500 normal 14px/21px $main_font !important;
    }
  }
  .game-line__visitor-line button, .game-line__home-line button
  {
    font: normal 600 normal 10px/12px $main_font;
    @include mq-min(1440px) {
      font: normal 600 normal 11px/12px $main_font;
    }
    @include mq-min(1600px) {
      font: normal 600 normal 13px/12px $main_font;
    }

  }

  .game-line__props a,
  {
    font: normal 600 normal 10px/12px $main_font;
    @include mq-min(1440px) {
      font: normal 600 normal 12px/12px $main_font;
    }

  }
}

//This remove the margin top just for home lines
.regular-home-lines {
  .game-line {
    &:first-of-type {
      margin-top: 0;
    }
  }
}


//Animations
@keyframes lineChangeDownOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lineChangeDown {
  0% {
    border: 2px solid $odds_down_color;
  }
  100% {
    border: 2px solid $odds_down_color;
  }
}

@keyframes lineChangeUp {
  0% {
    border: 2px solid $odds_up_color;
  }
  100% {
    border: 2px solid $odds_up_color;
  }
}


.live {
  &__title {
    color: $accent;
    margin: 10px 0 10px 0;

    &__bg {
      background-color: #25262B;
      border-radius: 8px 8px 0 0;
    }
  }
}

.sportsbook {
  &__line-date {
    color: $fontColor;
    font: normal 500 normal 10px/12px $main_font;
    @include mq-min(768px) {
      font: normal 500 normal 13px/21px $main_font;
    }
    @include mq-min(1280px) {
      font: normal 500 normal 13px/21px $main_font;
    }
  }
}

.betslip-icon{
  fill: $accent;
}
