// lines load animation
@import "lines/lines-load-animation";
// home lines
@import "lines/home-lines";
// lines tabs
@import "lines/lines-tabs";
// lines scroll menu items
@import "lines/sports-scroll-menu";
// lines custom dropdown -> breadcrumb
@import "lines/custom-drop";
@import "lines/breadcrum";
// betboost
@import "lines/betboost";
// game line
@import "lines/game-line-buttons";
@import "lines/game-line";

.lines-odds {
  background-color: $bg_button_line;
  color: $fontColor;

  span {
    color: $color_button_line_second;
  }

  @if $brand == 'xbet' {
    &.active {
      background-color: $bet-btn-active;
      color: $fontColor;

      span {
        color: $color_button_line_second !important;
      }

    }
  }
}

.dst-content {
  position: absolute;
  top: -3px;
  right: 15px;
  font-size: 1rem;
  @include mq-min(768px) {
    font-size: 1.7rem;
    top: -12px;
  }

  a {
    color: $accent;
  }
}

.dst-controls {
  .tab-icon {
    padding: 10px;
    background: $bg_title_lines;
    color: $white-color;
    font-size: 1.5rem;
    border-bottom: 3px solid $borderColor;

    &:hover {
      color: $white-color;
    }

    &.tab-tracker {
      border-top-left-radius: 8px;
    }

    &.tab-stream {
      border-top-right-radius: 8px;
    }

    &.active {
      color: $accent;
      border-bottom: 3px solid $accent;

      &:hover {
        color: $accent;
      }
    }
  }
}

//no lines
.text-no-games {
  color: $white-color;
}

.sportsbook__no-lines {
  p {
    color: $line-black-color;
    @include mq-max(560px) {
      font: normal 500 normal 12px/13px Montserrat, sans-serif;
    }
  }

  .list-group {
    li {
      background: $bg_body_line;

      a {
        color: $line-black-color;

        &:hover {
          color: $line-black-color;
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}