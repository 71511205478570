.game-lines__top_breadcrumb {
  &.pages {
    background: $breadcrumbBg;
    border-top: 2px solid $breadcrumb-border;
  }
}

.breadcrumb {
  background: $breadcrumbBg;
  padding: 0;
  margin: 0;
  border-radius: 0;

  .breadcrumb-item {
    display: flex;
    color: $breadcrumb-color;
    padding: 10px 8px 10px 0;
    font: normal 600 normal 10px/13px $main_font;
    @include mq-min(768px) {
      font: normal 600 normal 12px/15px $main_font;
    }
    @include mq-min(1280px) {
      font: normal 600 normal calc(0.5rem + 0.5vw)/18px $main_font;
    }

    a {
      color: $breadcrumb-color;

      &.drop-open {
        color: $breadcrumb-color-active;
      }
    }

    &:before {
      font-family: "mybv4" !important;
      text-transform: none !important;
      content: $breadcrumb-divider;
      color: $breadcrumb-color;
      padding-right: .5rem;
    }

    &:first-of-type {
      &:before {
        content: '';
        padding-right: 0;
      }
    }

    &.active {
      color: $breadcrumb-color-active;
    }
  }

  .breadcrumb-drop {
    position: absolute;
    width: 100%;
    top: 40px;
    z-index: 99;
    left: 0;
    right: 0;
    background: $breadcrumb-open-bg;
    @include mq-min(768px) {
      top: 50px;
      background: transparent;
      margin-top: 24px;
    }

    ul {
      padding: 0;
      list-style: none;
      background: $breadcrumb-open-bg;

      li {
        margin-bottom: 16px;
        background: $breadcrumb-open-items;
        @if $brand == 'mybookie' {
          border-radius: 8px;
        }

        a {
          display: block;
          color: $breadcrumb-open-items-color;
          font: normal 600 normal 10px/13px $main_font;
          padding: 16px;
          @include mq-min(768px) {
            font: normal 600 normal 12px/15px $main_font;
            padding: 16px;
          }
          @include mq-min(1040px) {
            font: normal 600 normal 16px/19px $main_font;
            padding: 24px;
          }

          i {
            display: flex;
            align-items: center;
            font-size: 18px;
          }
        }
      }
    }
  }

  .breadcrumb-add-fav-btn {
    background: transparent;
    border: none;
    position: absolute;
    right: 15px;
    font-size: 20px;
    @include mq-min(768px) {
      font-size: 25px;
    }
    @include mq-min(1040px) {
      font-size: 30px;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    span {
      color: $breadcrumb-fav;
    }

    &.onFav, &:hover {
      span {
        &:before {
          content: "\6f";
        }
      }
    }
  }
}