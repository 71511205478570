.playerStats {
  background-color: $bg_body_line;

  &__name {
    color: $accent;
  }
}

.teamStats {
  background-color: $bg_body_line;
  border: 1px solid $bg_body_line;
  color: #FFFFFF;
}
