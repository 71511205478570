@import 'helpers/variables.scss';
@import 'helpers/mixins.scss';

@import "components/carousel";
@import "components/lines";
@import "components/lastest-news"; //Lasted News
@import "components/stats"; //Stats

.swiper {
  width: 100%;
}

.swiper-slide__ob {
  img {
    height: 300px;
  }
}
