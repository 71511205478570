.game-lines {
  &__nav_container {
    position: relative;
    background: $nav-bg;

    &.home {
      border-radius: 8px;
    }

    &.home ~ &.home {
      margin-top: 32px;
      @include mq-min(1280px) {
        margin-top: 80px;
      }
    }

    &:before {
      content: "";
      width: 100%;
      height: 3px;
      background: $nav-border;
      position: absolute;
      bottom: 0;
      @include mq-min(1040px) {
        height: 5px;
      }
    }
  }

  &__top_breadcrumb {
    display: flex;
    align-items: center;
    height: 40px;
    @include mq-min(1040px) {
      height: 48px;
    }
  }

  &__line-tabs {
    height: 40px;
    @include mq-min(1280px) {
      height: 56px;
    }
  }

  &__navigation {
    .nav-home, .nav-lines {
      .nav-item {
        color: $nav-color;
        border-bottom: 5px solid transparent;
        padding: 15px 10px;
        @include mq-min(768px) {
          padding: 15px;
        }
        @include mq-min(1280px) {
          padding: 15px 30px;
        }
      }

      &.nav-home {
        .nav-item {
          font: normal 600 normal 13px/18px $main_font;
          @include mq-min(1280px) {
            font: normal 600 normal calc(0.2em + 1vw)/27px $main_font;
          }

          &.active {
            z-index: 1;

            &.live-link {
              border-bottom: 5px solid $live-color;
            }

            &.upcoming-link {
              border-bottom: 5px solid $upcoming-color;
            }

            &.featured-link {
              border-bottom: 5px solid $accent;
            }

            &.top-link {
              border-bottom: 5px solid $top-color;
            }
          }
        }
      }

      &.nav-lines {
        max-width: 100%;
        width: 100%;
        padding: 0;
        @include scroll-list;
        @include mq-min(1024px) {
          overflow: hidden;
          &.active {
            cursor: grabbing;
          }

          &::-webkit-scrollbar-track {
            //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 8px;
            background-color: #f6f4f5;
          }

          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: #F5F5F5;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            background-color: #f6f4f5;
          }
        }

        &:hover {
          overflow: auto;
        }

        //&::-webkit-scrollbar {
        //  display: none;
        //}

        li {
          margin: 0 12px;
          @include mq-min(768px) {
            margin: 0 15px;
          }

          &:first-of-type {
            margin-left: 0;
          }
        }

        .nav-item {
          font: normal 600 normal 13px/18px $main_font;
          padding: 10px 0;
          @include mq-min(1040px) {
            font: normal 600 normal calc(0.5rem + 0.5vw)/22px $main_font;
            padding: 15px 0;
          }

          &.menu_active, &.active {
            color: $line-active;
            border-bottom: 3px solid $line-active;
            position: relative;
            @include mq-min(1040px) {
              border-bottom: 5px solid $line-active;
            }
          }
        }
      }
    }
  }
}

.odds-banner {

  .od {
    &__container {
      height: 135px;
    }
  }

  .game-line {
    border-top: none;
  }

  .props_ob > a {
    font: normal 100 normal 12px/15px $main_font;
    margin-left: 5px;
  }
}

.rounded-top-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rounded-bottom-8 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.border-line-left {
  border-left: 0.5px solid gray;
}

.section-menu {
  background-color: $bg_main;
  border-radius: 8px;
  color: $anchor_color;
  margin-bottom: 10px;
  padding: 15px;
}
