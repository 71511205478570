.custom-drop{
  &__btn{
    background: #fff ;
    border-radius: 8px;
    border: none;
    font: normal 600 normal 14px/18px $main_font;
    color: #231F20;
    width: 100%;
    @include mq-min(1280px) {
      font: normal 600 normal calc(0.1em + 1vw)/14px $main_font;
    }
    &:focus, &:active{
      outline: none;
      box-shadow: none;
    }
    &:hover{
      background: #fff;
      color: #231F20;
    }
    &:after {
      display: none;
    }
  }
  &__menu{
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: 0 4px 8px #00000029;
    ul{
      padding: 0;
      margin: 0;
      .dropdown-item{
        &.active{
          background: #1976D2;
          a{
            color:#fff;
            &:hover{
              color: #fff;
            }
          }
        }
        a{
          font: normal 600 normal 14px/19px $main_font;
          color: #231F20;
        }
      }
    }
  }
}