/**
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for eight different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE: 
 *	@include r(240)  { }
 *	@include r(320)  { }
 *	@include r(480)  { }
 *	@include r(768)  { }
 *	@include r(992)  { }
 *	@include r(1024) { }
 *	@include r(1140) { }
 *	@include r(1280) { }
 *	@include r(1500) { }
 *
 */
/**
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for eight different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE:
 *	@include resolution(240)  { }
 *	@include resolution(320)  { }
 *	@include resolution(480)  { }
 *	@include resolution(768)  { }
 *	@include resolution(992)  { }
 *	@include resolution(1024) { }
 *	@include resolution(1140) { }
 *	@include resolution(1280) { }
 *	@include resolution(1500) { }
 *
 */
/****************Orientation***********************/
.carousel-container {
  padding: 15px 8px;
}
@media screen and (min-width: 768px) {
  .carousel-container.launch-game {
    padding: 10px 8px !important;
    border-radius: 8px;
    height: auto;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (min-width: 780px) {
  .carousel-container.launch-game {
    width: 98%;
  }
}
@media (max-width: 760px) {
  .carousel-container.launch-game {
    padding: 0 0 !important;
  }
}
.carousel-container .carousel-item div {
  padding: 0 8px;
}
.carousel-container .carousel-item div img {
  border-radius: 8px;
}
.carousel-container .carousel-inner .carousel-item.active,
.carousel-container .carousel-inner .carousel-item-next,
.carousel-container .carousel-inner .carousel-item-prev {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
}
.carousel-container .arrow {
  display: block;
}
.carousel-container .button_game img {
  min-height: 72px !important;
  max-height: 72px !important;
}
.carousel-container .button_game img.launch-game {
  border-radius: 8px;
}
.carousel-container:hover .arrow {
  display: flex;
}
.carousel-container .carousel-container-launch {
  width: 96%;
  left: 3%;
}
@media (min-width: 780px) {
  .carousel-container .carousel-container-launch {
    width: 98%;
    left: 1%;
  }
}
.carousel-container .swiper-pagination {
  bottom: 0 !important;
}
@media (min-width: 768px) {
  .carousel-container .swiper-pagination {
    left: -17% !important;
  }
}
.carousel-container .swiper-button-next {
  font-family: initial !important;
  background-image: url("../../../resources/images/default/carousel/Banner-Right-Arrow.svg");
  background-image: url("../../../resources/images/default/carousel/Banner-Right-Arrow.svg");
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-position: center;
  background-size: 150%;
}
@media (min-width: 1600px) {
  .carousel-container .swiper-button-next {
    width: 48px;
    height: 48px;
  }
}
.carousel-container .swiper-button-next:after {
  content: "";
}
.carousel-container .swiper-button-prev {
  font-family: initial !important;
  background-image: url("../../../resources/images/default/carousel/Banner-Left-Arrow.svg");
  background-image: url("../../../resources/images/default/carousel/Banner-Left-Arrow.svg");
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-position: center;
  background-size: 150%;
}
@media (min-width: 1600px) {
  .carousel-container .swiper-button-prev {
    width: 48px;
    height: 48px;
  }
}
.carousel-container .swiper-button-prev:after {
  content: "";
}
.carousel-container .odds-row-prev {
  background-image: url("../../../resources/images/default/carousel/Left-Arrow.svg") !important;
  background-size: 30px;
}
.carousel-container .odds-row-next {
  background-image: url("../../../resources/images/default/carousel/Right-Arrow.svg") !important;
  background-size: 30px;
}
.carousel-container .swiper-pagination-bullet-active {
  background: var(--accent-color) !important;
}
.carousel-container .swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.5);
}
.carousel-container.odds-banner {
  background: transparent;
}
.carousel-container.odds-banner .swiper-button-next, .carousel-container.odds-banner .swiper-button-prev {
  width: 30px;
  height: 30px;
  top: 55%;
}
@media (min-width: 1440px) {
  .carousel-container.odds-banner .swiper-button-next, .carousel-container.odds-banner .swiper-button-prev {
    width: 40px;
    height: 40px;
  }
}
.carousel-container.odds-banner .swiper-button-next {
  right: -5px;
}
.carousel-container.odds-banner .swiper-button-prev {
  left: -5px;
}

#sportsCarousel .swiper-wrapper {
  padding-bottom: 22px;
}

.img-banner {
  border-radius: 8px;
}

.load {
  background: #CFCDCD;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeLoadOpacity;
  background-size: 1200px 104px;
  color: transparent !important;
  pointer-events: none;
}
.load * {
  color: transparent;
}
.load:hover {
  cursor: default !important;
}

@keyframes placeload {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeLoadOpacity {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.5;
  }
  66% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
}
.free_wager_lines_tittle {
  color: #fff;
  margin-top: 1rem;
}

.game-lines-collapse {
  border-radius: 8px;
  background: #272829;
  cursor: pointer;
}
.game-lines-collapse__header {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  color: #fff;
}
.game-lines-collapse__header__sport {
  font: normal 600 normal 16px/19px "Montserrat", sans-serif;
}
.game-lines-collapse__header.collapsed .icon-arrow-up {
  transform: rotate(180deg);
}
.game-lines-collapse__body {
  background: #242526;
}

.top_bet_game:first-of-type {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.game-lines__nav_container {
  position: relative;
  background: #242526;
}
.game-lines__nav_container.home {
  border-radius: 8px;
}
.game-lines__nav_container.home ~ .game-lines__nav_container.home {
  margin-top: 32px;
}
@media screen and (min-width: 1280px) {
  .game-lines__nav_container.home ~ .game-lines__nav_container.home {
    margin-top: 80px;
  }
}
.game-lines__nav_container:before {
  content: "";
  width: 100%;
  height: 3px;
  background: #58595a;
  position: absolute;
  bottom: 0;
}
@media screen and (min-width: 1040px) {
  .game-lines__nav_container:before {
    height: 5px;
  }
}
.game-lines__top_breadcrumb {
  display: flex;
  align-items: center;
  height: 40px;
}
@media screen and (min-width: 1040px) {
  .game-lines__top_breadcrumb {
    height: 48px;
  }
}
.game-lines__line-tabs {
  height: 40px;
}
@media screen and (min-width: 1280px) {
  .game-lines__line-tabs {
    height: 56px;
  }
}
.game-lines__navigation .nav-home .nav-item, .game-lines__navigation .nav-lines .nav-item {
  color: #fff;
  border-bottom: 5px solid transparent;
  padding: 15px 10px;
}
@media screen and (min-width: 768px) {
  .game-lines__navigation .nav-home .nav-item, .game-lines__navigation .nav-lines .nav-item {
    padding: 15px;
  }
}
@media screen and (min-width: 1280px) {
  .game-lines__navigation .nav-home .nav-item, .game-lines__navigation .nav-lines .nav-item {
    padding: 15px 30px;
  }
}
.game-lines__navigation .nav-home.nav-home .nav-item, .game-lines__navigation .nav-lines.nav-home .nav-item {
  font: normal 600 normal 13px/18px "Montserrat", sans-serif;
}
@media screen and (min-width: 1280px) {
  .game-lines__navigation .nav-home.nav-home .nav-item, .game-lines__navigation .nav-lines.nav-home .nav-item {
    font: normal 600 normal calc(0.2em + 1vw)/27px "Montserrat", sans-serif;
  }
}
.game-lines__navigation .nav-home.nav-home .nav-item.active, .game-lines__navigation .nav-lines.nav-home .nav-item.active {
  z-index: 1;
}
.game-lines__navigation .nav-home.nav-home .nav-item.active.live-link, .game-lines__navigation .nav-lines.nav-home .nav-item.active.live-link {
  border-bottom: 5px solid #91D146;
}
.game-lines__navigation .nav-home.nav-home .nav-item.active.upcoming-link, .game-lines__navigation .nav-lines.nav-home .nav-item.active.upcoming-link {
  border-bottom: 5px solid #1976D2;
}
.game-lines__navigation .nav-home.nav-home .nav-item.active.featured-link, .game-lines__navigation .nav-lines.nav-home .nav-item.active.featured-link {
  border-bottom: 5px solid #F48220;
}
.game-lines__navigation .nav-home.nav-home .nav-item.active.top-link, .game-lines__navigation .nav-lines.nav-home .nav-item.active.top-link {
  border-bottom: 5px solid #F48220;
}
.game-lines__navigation .nav-home.nav-lines, .game-lines__navigation .nav-lines.nav-lines {
  max-width: 100%;
  width: 100%;
  padding: 0;
  cursor: pointer;
  display: flex;
  list-style: none;
  margin: 0;
  overflow: auto;
  white-space: nowrap;
  webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-overflow-style: none;
}
@media screen and (min-width: 1024px) {
  .game-lines__navigation .nav-home.nav-lines, .game-lines__navigation .nav-lines.nav-lines {
    overflow: hidden;
  }
  .game-lines__navigation .nav-home.nav-lines.active, .game-lines__navigation .nav-lines.nav-lines.active {
    cursor: grabbing;
  }
  .game-lines__navigation .nav-home.nav-lines::-webkit-scrollbar-track, .game-lines__navigation .nav-lines.nav-lines::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #f6f4f5;
  }
  .game-lines__navigation .nav-home.nav-lines::-webkit-scrollbar, .game-lines__navigation .nav-lines.nav-lines::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #F5F5F5;
  }
  .game-lines__navigation .nav-home.nav-lines::-webkit-scrollbar-thumb, .game-lines__navigation .nav-lines.nav-lines::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f6f4f5;
  }
}
.game-lines__navigation .nav-home.nav-lines:hover, .game-lines__navigation .nav-lines.nav-lines:hover {
  overflow: auto;
}
.game-lines__navigation .nav-home.nav-lines li, .game-lines__navigation .nav-lines.nav-lines li {
  margin: 0 12px;
}
@media screen and (min-width: 768px) {
  .game-lines__navigation .nav-home.nav-lines li, .game-lines__navigation .nav-lines.nav-lines li {
    margin: 0 15px;
  }
}
.game-lines__navigation .nav-home.nav-lines li:first-of-type, .game-lines__navigation .nav-lines.nav-lines li:first-of-type {
  margin-left: 0;
}
.game-lines__navigation .nav-home.nav-lines .nav-item, .game-lines__navigation .nav-lines.nav-lines .nav-item {
  font: normal 600 normal 13px/18px "Montserrat", sans-serif;
  padding: 10px 0;
}
@media screen and (min-width: 1040px) {
  .game-lines__navigation .nav-home.nav-lines .nav-item, .game-lines__navigation .nav-lines.nav-lines .nav-item {
    font: normal 600 normal calc(0.5rem + 0.5vw)/22px "Montserrat", sans-serif;
    padding: 15px 0;
  }
}
.game-lines__navigation .nav-home.nav-lines .nav-item.menu_active, .game-lines__navigation .nav-home.nav-lines .nav-item.active, .game-lines__navigation .nav-lines.nav-lines .nav-item.menu_active, .game-lines__navigation .nav-lines.nav-lines .nav-item.active {
  border-bottom: 3px solid;
  position: relative;
}
@media screen and (min-width: 1040px) {
  .game-lines__navigation .nav-home.nav-lines .nav-item.menu_active, .game-lines__navigation .nav-home.nav-lines .nav-item.active, .game-lines__navigation .nav-lines.nav-lines .nav-item.menu_active, .game-lines__navigation .nav-lines.nav-lines .nav-item.active {
    border-bottom: 5px solid;
  }
}

.odds-banner .od__container {
  height: 135px;
}
.odds-banner .game-line {
  border-top: none;
}
.odds-banner .props_ob > a {
  font: normal 100 normal 12px/15px "Montserrat", sans-serif;
  margin-left: 5px;
}

.rounded-top-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rounded-bottom-8 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.border-line-left {
  border-left: 0.5px solid gray;
}

.section-menu {
  background-color: #333435;
  border-radius: 8px;
  color: white;
  margin-bottom: 10px;
  padding: 15px;
}

.sports-scroll-menu__main {
  background: #17191A;
  padding-bottom: 5px;
}
.sports-scroll-menu__draggable {
  overflow: auto;
}

.custom-drop__btn {
  background: #fff;
  border-radius: 8px;
  border: none;
  font: normal 600 normal 14px/18px "Montserrat", sans-serif;
  color: #231F20;
  width: 100%;
}
@media screen and (min-width: 1280px) {
  .custom-drop__btn {
    font: normal 600 normal calc(0.1em + 1vw)/14px "Montserrat", sans-serif;
  }
}
.custom-drop__btn:focus, .custom-drop__btn:active {
  outline: none;
  box-shadow: none;
}
.custom-drop__btn:hover {
  background: #fff;
  color: #231F20;
}
.custom-drop__btn:after {
  display: none;
}
.custom-drop__menu {
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1607843137);
}
.custom-drop__menu ul {
  padding: 0;
  margin: 0;
}
.custom-drop__menu ul .dropdown-item.active {
  background: #1976D2;
}
.custom-drop__menu ul .dropdown-item.active a {
  color: #fff;
}
.custom-drop__menu ul .dropdown-item.active a:hover {
  color: #fff;
}
.custom-drop__menu ul .dropdown-item a {
  font: normal 600 normal 14px/19px "Montserrat", sans-serif;
  color: #231F20;
}

.game-lines__top_breadcrumb.pages {
  background: #333435;
  border-top: 2px solid;
}

.breadcrumb {
  background: #333435;
  padding: 0;
  margin: 0;
  border-radius: 0;
}
.breadcrumb .breadcrumb-item {
  display: flex;
  color: #fff;
  padding: 10px 8px 10px 0;
  font: normal 600 normal 10px/13px "Montserrat", sans-serif;
}
@media screen and (min-width: 768px) {
  .breadcrumb .breadcrumb-item {
    font: normal 600 normal 12px/15px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .breadcrumb .breadcrumb-item {
    font: normal 600 normal calc(0.5rem + 0.5vw)/18px "Montserrat", sans-serif;
  }
}
.breadcrumb .breadcrumb-item a {
  color: #fff;
}
.breadcrumb .breadcrumb-item a.drop-open {
  color: #F48220;
}
.breadcrumb .breadcrumb-item:before {
  font-family: "mybv4" !important;
  text-transform: none !important;
  content: "C";
  color: #fff;
  padding-right: 0.5rem;
}
.breadcrumb .breadcrumb-item:first-of-type:before {
  content: "";
  padding-right: 0;
}
.breadcrumb .breadcrumb-item.active {
  color: #F48220;
}
.breadcrumb .breadcrumb-drop {
  position: absolute;
  width: 100%;
  top: 40px;
  z-index: 99;
  left: 0;
  right: 0;
}
@media screen and (min-width: 768px) {
  .breadcrumb .breadcrumb-drop {
    top: 50px;
    background: transparent;
    margin-top: 24px;
  }
}
.breadcrumb .breadcrumb-drop ul {
  padding: 0;
  list-style: none;
}
.breadcrumb .breadcrumb-drop ul li {
  margin-bottom: 16px;
  border-radius: 8px;
}
.breadcrumb .breadcrumb-drop ul li a {
  display: block;
  font: normal 600 normal 10px/13px "Montserrat", sans-serif;
  padding: 16px;
}
@media screen and (min-width: 768px) {
  .breadcrumb .breadcrumb-drop ul li a {
    font: normal 600 normal 12px/15px "Montserrat", sans-serif;
    padding: 16px;
  }
}
@media screen and (min-width: 1040px) {
  .breadcrumb .breadcrumb-drop ul li a {
    font: normal 600 normal 16px/19px "Montserrat", sans-serif;
    padding: 24px;
  }
}
.breadcrumb .breadcrumb-drop ul li a i {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.breadcrumb .breadcrumb-add-fav-btn {
  background: transparent;
  border: none;
  position: absolute;
  right: 15px;
  font-size: 20px;
}
@media screen and (min-width: 768px) {
  .breadcrumb .breadcrumb-add-fav-btn {
    font-size: 25px;
  }
}
@media screen and (min-width: 1040px) {
  .breadcrumb .breadcrumb-add-fav-btn {
    font-size: 30px;
  }
}
.breadcrumb .breadcrumb-add-fav-btn:focus {
  box-shadow: none;
  outline: none;
}
.breadcrumb .breadcrumb-add-fav-btn.onFav span:before, .breadcrumb .breadcrumb-add-fav-btn:hover span:before {
  content: "o";
}

.bet-boost {
  color: #fff;
}
.bet-boost .bb-header {
  padding: 0 10px;
  background-color: #333435;
  border-radius: 8px 8px 0 0;
}
.bet-boost .bb-body {
  background-color: #242526;
}
.bet-boost .bb-body .sub-title {
  font: normal 500 normal 14px/16px "Montserrat", sans-serif;
}
.bet-boost .bb-button {
  padding: 0;
  background-color: hsla(0, 0%, 93%, 0.15);
  border-radius: 0 0 8px 8px;
}
.bet-boost .bb-button button {
  color: #fff;
}
.bet-boost .bb-button .odds {
  font: normal 600 normal 22px/26px "Montserrat", sans-serif;
}
.bet-boost .bb-button .odds-invalid {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-thickness: 2px;
}
.bet-boost .bb-card {
  border: none;
  background-color: transparent;
}
.bet-boost .bb-accent {
  color: #F48220;
}
.bet-boost .bb-svg {
  fill: #F48220;
}

.top-bet-box {
  flex: 0 0 49.5%;
}

.bi-lock-fill {
  fill: #F48220;
}

.game-line {
  background: #242526;
}
.game-line:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-bottom: 0.5rem;
}
.game-line__only-time {
  margin-right: 10px;
  letter-spacing: 0;
  color: #FFFFFF !important;
  opacity: 0.75;
  font: normal 100 normal 10px/12px "Montserrat", sans-serif;
}
@media screen and (min-width: 768px) {
  .game-line__only-time {
    font: normal 500 normal 12px/15px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .game-line__only-time {
    font: normal 500 normal 14px/21px "Montserrat", sans-serif;
  }
}
.game-line__only-time__ob {
  font: normal 100 normal 10px/15px "Montserrat", sans-serif;
}
.game-line__header {
  background-color: #333435;
  padding-bottom: 0.2rem;
}
.game-line__header.header-top-bets {
  background-color: #242526;
  padding-top: 10px;
}
.game-line__header p {
  color: #fff;
  font: normal 100 normal 12px/14px "Montserrat", sans-serif;
}
.game-line__header_date {
  padding: 10px 0 3px 5px;
}
.game-line__time__date {
  font: normal 500 normal 10px/13px "Montserrat", sans-serif;
  color: white;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .game-line__time__date {
    font: normal 500 normal 12px/15px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .game-line__time__date {
    font: normal 500 normal 14px/18px "Montserrat", sans-serif;
  }
}
.game-line__time__date .live-current-period i {
  font-size: 0.5rem;
}
.game-line__type__name {
  max-width: 208px;
  width: 100%;
  font: normal 500 normal 10px/13px "Montserrat", sans-serif;
  margin: 0;
  color: hsla(0, 0%, 100%, 0.75);
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .game-line__type__name {
    font: normal 500 normal 12px/15px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .game-line__type__name {
    font: normal 500 normal 14px/18px "Montserrat", sans-serif;
  }
}
.game-line__type__name.tnt-name {
  max-width: 100%;
}
.game-line__description, .game-line__banner, .game-line__description a, .game-line__banner a {
  color: white;
  font: normal 500 normal 10px/13px "Montserrat", sans-serif;
}
@media screen and (min-width: 768px) {
  .game-line__description, .game-line__banner, .game-line__description a, .game-line__banner a {
    font: normal 500 normal 12px/15px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .game-line__description, .game-line__banner, .game-line__description a, .game-line__banner a {
    font: normal 500 normal 12px/16px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1600px) {
  .game-line__description, .game-line__banner, .game-line__description a, .game-line__banner a {
    font: normal 500 normal 14px/16px "Montserrat", sans-serif;
  }
}
.game-line__description.league-title, .game-line__banner.league-title, .game-line__description a.league-title, .game-line__banner a.league-title {
  background: #333435;
  padding: 10px 0 10px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
  margin-top: 10px;
}
.game-line__visitor-team__name, .game-line__home-team__name, .game-line__top-bet-teams__name, .game-line__tnt-team__name {
  max-width: 100%;
  word-break: break-word;
  color: white;
  font: normal 700 normal 10px/12px "Montserrat", sans-serif;
  text-transform: capitalize;
}
@media screen and (min-width: 768px) {
  .game-line__visitor-team__name, .game-line__home-team__name, .game-line__top-bet-teams__name, .game-line__tnt-team__name {
    font: normal 700 normal 12px/16px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .game-line__visitor-team__name, .game-line__home-team__name, .game-line__top-bet-teams__name, .game-line__tnt-team__name {
    font: normal 700 normal 14px/16px "Montserrat", sans-serif;
  }
}
.game-line__visitor-team__name a, .game-line__home-team__name a, .game-line__top-bet-teams__name a, .game-line__tnt-team__name a {
  color: white;
}
.game-line__visitor-team__name a:hover, .game-line__home-team__name a:hover, .game-line__top-bet-teams__name a:hover, .game-line__tnt-team__name a:hover {
  color: white;
}
.game-line__visitor-team__name .score_live, .game-line__home-team__name .score_live, .game-line__top-bet-teams__name .score_live, .game-line__tnt-team__name .score_live {
  position: absolute;
  right: 0;
}
.game-line__top-bet-teams__name, .game-line__tnt-team__name {
  max-width: 100%;
}
.game-line__visitor-line, .game-line__home-line, .game-line__top-bet-line, .game-line__tnt-line {
  margin-bottom: 8px;
}
.game-line__visitor-line button, .game-line__home-line button, .game-line__top-bet-line button, .game-line__tnt-line button {
  position: relative;
  border-radius: 4px;
  width: 100%;
  min-height: 32px;
  margin-right: 4px;
  font: normal 500 normal 10px/13px "Montserrat", sans-serif;
  border: 2px solid transparent;
}
@media screen and (min-width: 768px) {
  .game-line__visitor-line button, .game-line__home-line button, .game-line__top-bet-line button, .game-line__tnt-line button {
    font: normal 500 normal 12px/15px "Montserrat", sans-serif;
    min-height: 40px;
    margin-right: 8px;
  }
}
@media screen and (min-width: 1280px) {
  .game-line__visitor-line button, .game-line__home-line button, .game-line__top-bet-line button, .game-line__tnt-line button {
    font: normal 500 normal 13px/18px "Montserrat", sans-serif;
    height: 48px;
  }
}
@media screen and (min-width: 1600px) {
  .game-line__visitor-line button, .game-line__home-line button, .game-line__top-bet-line button, .game-line__tnt-line button {
    font: normal 500 normal 14px/18px "Montserrat", sans-serif;
  }
}
.game-line__visitor-line button:last-of-type, .game-line__home-line button:last-of-type, .game-line__top-bet-line button:last-of-type, .game-line__tnt-line button:last-of-type {
  margin-right: 0;
}
.game-line__visitor-line button span, .game-line__home-line button span, .game-line__top-bet-line button span, .game-line__tnt-line button span {
  color: #F48220;
  font-weight: 600;
}
.game-line__visitor-line button.active, .game-line__home-line button.active, .game-line__top-bet-line button.active, .game-line__tnt-line button.active {
  background: #1C67BB;
  color: #fff;
}
.game-line__visitor-line button:focus, .game-line__visitor-line button:active, .game-line__home-line button:focus, .game-line__home-line button:active, .game-line__top-bet-line button:focus, .game-line__top-bet-line button:active, .game-line__tnt-line button:focus, .game-line__tnt-line button:active {
  outline: none;
  box-shadow: none;
}
.game-line__visitor-line button:before, .game-line__visitor-line button:after, .game-line__home-line button:before, .game-line__home-line button:after, .game-line__top-bet-line button:before, .game-line__top-bet-line button:after, .game-line__tnt-line button:before, .game-line__tnt-line button:after {
  content: "b";
  text-transform: none !important;
  font-family: "mybv4" !important;
  opacity: 0;
  position: absolute;
  right: 3px;
  font-size: 7px;
  top: 30%;
}
@media screen and (min-width: 768px) {
  .game-line__visitor-line button:before, .game-line__visitor-line button:after, .game-line__home-line button:before, .game-line__home-line button:after, .game-line__top-bet-line button:before, .game-line__top-bet-line button:after, .game-line__tnt-line button:before, .game-line__tnt-line button:after {
    right: 14px;
    font-size: 14px;
  }
}
@media screen and (min-width: 1280px) {
  .game-line__visitor-line button:before, .game-line__visitor-line button:after, .game-line__home-line button:before, .game-line__home-line button:after, .game-line__top-bet-line button:before, .game-line__top-bet-line button:after, .game-line__tnt-line button:before, .game-line__tnt-line button:after {
    right: 16px;
    font-size: 16px;
  }
}
.game-line__visitor-line button:before, .game-line__home-line button:before, .game-line__top-bet-line button:before, .game-line__tnt-line button:before {
  color: #70A800;
}
.game-line__visitor-line button:after, .game-line__home-line button:after, .game-line__top-bet-line button:after, .game-line__tnt-line button:after {
  color: #EA0070;
  transform: rotate(180deg);
}
.game-line__visitor-line button.button_line_change_color.button_line_change_up, .game-line__home-line button.button_line_change_color.button_line_change_up, .game-line__top-bet-line button.button_line_change_color.button_line_change_up, .game-line__tnt-line button.button_line_change_color.button_line_change_up {
  animation: lineChangeUp 5s linear;
}
.game-line__visitor-line button.button_line_change_color.button_line_change_up:before, .game-line__home-line button.button_line_change_color.button_line_change_up:before, .game-line__top-bet-line button.button_line_change_color.button_line_change_up:before, .game-line__tnt-line button.button_line_change_color.button_line_change_up:before {
  animation: lineChangeDownOpacity 5s;
}
.game-line__visitor-line button.button_line_change_color.button_line_change_down, .game-line__home-line button.button_line_change_color.button_line_change_down, .game-line__top-bet-line button.button_line_change_color.button_line_change_down, .game-line__tnt-line button.button_line_change_color.button_line_change_down {
  animation: lineChangeDown 5s linear;
}
.game-line__visitor-line button.button_line_change_color.button_line_change_down:after, .game-line__home-line button.button_line_change_color.button_line_change_down:after, .game-line__top-bet-line button.button_line_change_color.button_line_change_down:after, .game-line__tnt-line button.button_line_change_color.button_line_change_down:after {
  animation: lineChangeDownOpacity 5s;
}
.game-line__top-bet-line button {
  min-height: 32px;
  width: 100%;
}
.game-line__top-bet-line button:not(.top-bet-btn) {
  max-width: 49%;
}
@media screen and (min-width: 768px) {
  .game-line__top-bet-line button {
    max-width: 249px;
    min-height: 40px;
  }
}
@media screen and (min-width: 992px) {
  .game-line__top-bet-line button {
    max-width: 600px;
    min-height: 48px;
    flex: 0 0 49.6%;
  }
}
.game-line__top-bet-line button.button-top-bet {
  border-color: #F48220;
}
.game-line__top-bet-line .top-bet-line-icon {
  position: absolute;
  color: #F48220;
  left: 8px;
  top: 38%;
  font-size: 10px;
}
@media screen and (min-width: 768px) {
  .game-line__top-bet-line .top-bet-line-icon {
    top: 30%;
    left: 20px;
    font-size: 1rem;
  }
}
.game-line__tnt-line {
  flex-direction: row;
  flex-wrap: wrap;
}
.game-line__tnt-line button {
  min-height: 32px;
  width: 100%;
}
.game-line__tnt-line button:not(.top-bet-btn) {
  max-width: 49%;
}
@media screen and (min-width: 768px) {
  .game-line__tnt-line button {
    max-width: 249px;
    min-height: 40px;
  }
}
@media screen and (min-width: 992px) {
  .game-line__tnt-line button {
    max-width: 600px;
    min-height: 48px;
    flex: 0 0 49.6%;
  }
}
.game-line__tnt-line button.button-top-bet {
  border-color: #F48220;
}
.game-line__tnt-line button {
  margin-right: 0;
  margin-bottom: 8px;
}
.game-line__stats {
  margin: 0;
}
.game-line__stats button {
  color: #F48220;
  font: normal 500 normal 10px/12px "Montserrat", sans-serif;
  border: none;
  background: none;
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .game-line__stats button {
    font: normal 500 normal 12px/16px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .game-line__stats button {
    font: normal 500 normal 14px/16px "Montserrat", sans-serif;
  }
}
.game-line__stats button:focus, .game-line__stats button:active {
  outline: none;
  box-shadow: none;
}
.game-line__props {
  margin: 0;
}
.game-line__props a {
  color: #F48220;
  font: normal 500 normal 10px/12px "Montserrat", sans-serif;
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .game-line__props a {
    font: normal 500 normal 13px/21px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .game-line__props a {
    font: normal 500 normal 13px/21px "Montserrat", sans-serif;
  }
}
.game-line .draw__info {
  padding: 10px 10px 0 0;
  margin-top: 5px;
  border-left: 15px solid #242526;
}
.game-line .draw__buttons {
  border-top: 0 solid #333;
  padding: 0 0 0 10px;
  margin-top: 0;
  border-right: 15px solid #242526;
}
.game-line__options {
  background-color: #242526;
  padding: 15px 0 15px 15px;
  color: #fff;
  font: normal 400 normal 14px/27px "Montserrat", sans-serif;
  margin-bottom: 0;
}
.game-line__result-row {
  position: relative;
}
.game-line__result-row:after {
  content: "";
  width: 35%;
  background: #58595A;
  height: 1px;
  top: 15px;
  position: absolute;
  left: 3px;
}
.game-line__result-row:before {
  content: "";
  width: 35%;
  background: #58595A;
  height: 1px;
  top: 15px;
  position: absolute;
  right: 3px;
}

.odds-banner .game-line__visitor-team__name, .odds-banner .game-line__type__name, .odds-banner .game-line__time__date, .odds-banner .game-line__home-team__name {
  font: normal 500 normal 11px/15px "Montserrat", sans-serif !important;
}
@media screen and (min-width: 1440px) {
  .odds-banner .game-line__visitor-team__name, .odds-banner .game-line__type__name, .odds-banner .game-line__time__date, .odds-banner .game-line__home-team__name {
    font: normal 500 normal 12px/18px "Montserrat", sans-serif !important;
  }
}
@media screen and (min-width: 1600px) {
  .odds-banner .game-line__visitor-team__name, .odds-banner .game-line__type__name, .odds-banner .game-line__time__date, .odds-banner .game-line__home-team__name {
    font: normal 500 normal 14px/21px "Montserrat", sans-serif !important;
  }
}
.odds-banner .game-line__visitor-line button, .odds-banner .game-line__home-line button {
  font: normal 600 normal 10px/12px "Montserrat", sans-serif;
}
@media screen and (min-width: 1440px) {
  .odds-banner .game-line__visitor-line button, .odds-banner .game-line__home-line button {
    font: normal 600 normal 11px/12px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1600px) {
  .odds-banner .game-line__visitor-line button, .odds-banner .game-line__home-line button {
    font: normal 600 normal 13px/12px "Montserrat", sans-serif;
  }
}
.odds-banner .game-line__props a {
  font: normal 600 normal 10px/12px "Montserrat", sans-serif;
}
@media screen and (min-width: 1440px) {
  .odds-banner .game-line__props a {
    font: normal 600 normal 12px/12px "Montserrat", sans-serif;
  }
}

.regular-home-lines .game-line:first-of-type {
  margin-top: 0;
}

@keyframes lineChangeDownOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lineChangeDown {
  0% {
    border: 2px solid #EA0070;
  }
  100% {
    border: 2px solid #EA0070;
  }
}
@keyframes lineChangeUp {
  0% {
    border: 2px solid #70A800;
  }
  100% {
    border: 2px solid #70A800;
  }
}
.live__title {
  color: #F48220;
  margin: 10px 0 10px 0;
}
.live__title__bg {
  background-color: #25262B;
  border-radius: 8px 8px 0 0;
}

.sportsbook__line-date {
  color: #fff;
  font: normal 500 normal 10px/12px "Montserrat", sans-serif;
}
@media screen and (min-width: 768px) {
  .sportsbook__line-date {
    font: normal 500 normal 13px/21px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .sportsbook__line-date {
    font: normal 500 normal 13px/21px "Montserrat", sans-serif;
  }
}

.betslip-icon {
  fill: #F48220;
}

.lines-odds {
  background-color: hsla(0, 0%, 93%, 0.15);
  color: #fff;
}
.lines-odds span {
  color: #F48220;
}

.dst-content {
  position: absolute;
  top: -3px;
  right: 15px;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .dst-content {
    font-size: 1.7rem;
    top: -12px;
  }
}
.dst-content a {
  color: #F48220;
}

.dst-controls .tab-icon {
  padding: 10px;
  background: #333435;
  color: #fff;
  font-size: 1.5rem;
  border-bottom: 3px solid;
}
.dst-controls .tab-icon:hover {
  color: #fff;
}
.dst-controls .tab-icon.tab-tracker {
  border-top-left-radius: 8px;
}
.dst-controls .tab-icon.tab-stream {
  border-top-right-radius: 8px;
}
.dst-controls .tab-icon.active {
  color: #F48220;
  border-bottom: 3px solid #F48220;
}
.dst-controls .tab-icon.active:hover {
  color: #F48220;
}

.text-no-games {
  color: #fff;
}

.sportsbook__no-lines p {
  color: white;
}
@media screen and (max-width: 560px) {
  .sportsbook__no-lines p {
    font: normal 500 normal 12px/13px Montserrat, sans-serif;
  }
}
.sportsbook__no-lines .list-group li {
  background: #242526;
}
.sportsbook__no-lines .list-group li a {
  color: white;
}
.sportsbook__no-lines .list-group li a:hover {
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.latest-news {
  background: #242526;
}
.latest-news .card-header {
  border-bottom: 1px solid;
  padding: 16px 24px;
  background: #272829;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.latest-news .card-header a {
  font: normal 600 normal 22px/22px "Montserrat", sans-serif;
  color: #F48220;
}
.latest-news .card-body {
  background: #242526;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.latest-news__title {
  font: normal 600 normal 20px/22px "Montserrat", sans-serif;
  color: #fff;
}
.latest-news__body img {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 15px auto !important;
}
.latest-news__body .media-body p {
  font: normal 500 normal 14px/22px "Montserrat", sans-serif;
  color: #fff;
  text-align: justify;
}
.latest-news__read-more-btn {
  font: normal 500 normal 14px/22px "Montserrat", sans-serif;
  border: 2px solid #F48220;
  color: #fff;
}
.latest-news__read-more-btn:hover {
  color: #F48220;
}

.playerStats {
  background-color: #242526;
}
.playerStats__name {
  color: #F48220;
}

.teamStats {
  background-color: #242526;
  border: 1px solid #242526;
  color: #FFFFFF;
}

.swiper {
  width: 100%;
}

.swiper-slide__ob img {
  height: 300px;
}