.free_wager_lines_tittle{
  color: $fontColor;
  margin-top: 1rem;
}

.game-lines-collapse{
  border-radius: 8px;
  background: $headerBg;
  cursor: pointer;

  &__header{
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color:$fontColor;
    &__sport{
      font: normal 600 normal 16px/19px $main_font;
    }

    &.collapsed{
      .icon-arrow-up{
        transform: rotate(180deg);
      }
    }
  }

  &__body{
    background: $bgColor;
  }
}

.top_bet_game{
  &:first-of-type{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}