.latest-news {
  background: $bgColor;

  .card-header {
    border-bottom: 1px solid $borderColor;
    padding: 16px 24px;
    background: $headerBg;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    a {
      font: normal 600 normal 22px/22px $main_font;
      color: $titleColor;
    }
  }

  .card-body {
    background: $bgColor;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &__title {
    font: normal 600 normal 20px/22px $main_font;
    color: $fontColor;
  }

  &__body {
    img {
      padding: .25rem;
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: .25rem;
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto 15px auto !important;
    }

    .media-body p {
      font: normal 500 normal 14px/22px $main_font;
      color: $fontColor;
      text-align: justify;
    }
  }

  &__read-more-btn {
    font: normal 500 normal 14px/22px $main_font;
    border: 2px solid $titleColor;
    color: $fontColor;

    &:hover {
      color: $titleColor;
    }
  }
}