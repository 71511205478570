$menuBg: null;
$arrowColor: null;
@if $brand == 'mybookie' {

  @if $mode == 'light'{
    $menuBg: #F6F4F5;
    $arrowColor: #fff;
  }

  @if $mode == 'dark'{
    $menuBg: #17191A;
    $arrowColor: #242526;
  }
}

@if $brand == 'xbet' {
  @if $mode == 'dark'{
    $menuBg: #17191A;
    $arrowColor: #242526;
  }
}

.sports-scroll-menu{
  &__main{
    background: $menuBg;
    padding-bottom: 5px;
  }
  &__draggable{
    overflow: auto;
//    padding: 0;
//    //height: 100%;
//    max-width: 100%;
//    width: 100%;
//    @include scroll-list;
//    @include mq-min(1040px) {
//      overflow: hidden;
//    }
//    &::-webkit-scrollbar
//    {
//      display: none;
//      opacity: 0;
//    }
//    &.active{
//      cursor: grabbing;
//      cursor: -webkit-grabbing;
//    }
//    &__item{
//      @include mq-min(1280px) {
//        padding: 15px 12px;
//      }
//      &__link{
//        color: $nav-color-bold;
//        //text-transform: capitalize;
//        position: relative;
//        @include mq-min(1280px) {
//          font: normal 600 normal calc(0.1em + 0.8vw)/19px $main_font;
//        }
//        &.active{
//          &:before {
//            text-transform: none !important;
//            font-family: "mybv4" !important;
//            content: "\6a";
//            height: 0;
//            position: absolute;
//            right: 0;
//            width: 100%;
//            left: 0;
//            margin: 0 auto;
//            bottom: -4px;
//            color: $arrowColor;
//            font-size: 17px;
//            text-align: center;
//          }
//        }
//        &.item_upcoming_menu{
//          &:hover{
//            color: $upcoming-color;
//          }
//          &.active{
//            color: $upcoming-color;
//          }
//        }
//        &.item_live_menu{
//          &:hover{
//            color: $live-color;
//          }
//          &.active{
//            color: $live-color;
//          }
//        }
//        &.item_featured_menu{
//          &:hover{
//            color: $accent;
//          }
//          &.active{
//            color: $accent;
//          }
//        }
//      }
//    }
  }
}